<template>
  <div class="page">
    <div class="main">
      <div class="title">商品列表</div>
      <el-row style="margin-top: 10px; min-height: 580px">
        <!--搜索start-->

        <el-input
          placeholder="订单号"
          v-model="order_no"
          style="width: 120px; margin-right: 20px"
          size="mini"
        ></el-input>
        <el-select
          v-model="order_status"
          class="filter-item"
          placeholder="订单状态"
          size="mini"
          style="width: 120px"
          clearable
        >
          <el-option :key="0" label="未支付" :value="0" />
          <el-option :key="1" label="已支付" :value="1" />
        </el-select>
        <el-button
          type="primary"
          size="mini"
          @click="getList"
          style="margin-left: 30px"
          >搜索</el-button
        >
        <!--搜索end-->
        <el-table
          :header-cell-style="rowClass"
          :key="tables.tableKey"
          v-loading="tables.listLoading"
          :data="tables.tableData"
          border
          :cell-style="columnStyle"
          highlight-current-row
          style="width: 100%; margin-top: 20px"
          height="580"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
          />
          <el-table-column
            prop="user_id"
            label="用户ID"
            width="80"
            show-overflow-tooltip
          />
          <el-table-column
            prop="order_no"
            label="订单号"
            width="180"
            show-overflow-tooltip
          />
          <el-table-column prop="order_status" label="订单状态" width="90">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.order_status == 0 ? 'danger' : 'success'"
                disable-transitions
                >{{ scope.row.order_status == 0 ? "未支付" : "已支付" }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="goods_name" label="商品名称" width="100" />
          <el-table-column prop="amount" label="价格" width="80" />
          <el-table-column prop="add_time" label="添加时间" width="180" />
          <el-table-column prop="pay_time" label="支付时间" width="180" />
          <el-table-column prop="phone" label="手机号" width="110" />
          <el-table-column prop="address" label="地址" width="189" />
          <el-table-column prop="user_name" label="收货人姓名" width="100" />

          <el-table-column
            label="操作"
            width="auto"
            fixed="right"
            min-width="200px"
          >
            <template slot-scope="scope">
              <i
                class="el-icon-edit"
                @click="handleEdit(scope.row.id)"
                style="margin-left: 20px"
                >编辑</i
              >
              <i
                class="el-icon-close"
                @click="handleDel(scope.row.id)"
                style="margin-left: 20px"
                >删除</i
              >
            </template>
          </el-table-column>
        </el-table>

        <div style="margin-top: 10px">
          <template>
            <div class="block">
              <el-pagination
                layout="prev, pager, next"
                :current-page="tables.currentPage"
                :total="tables.total"
                @current-change="handleCurrentChange"
              />
            </div>
          </template>
        </div>
      </el-row>

      <!--    表单提交-->
      <el-dialog
        :title="dialog.title"
        :visible.sync="dialog.centerDialogVisible"
        :close-on-click-modal="false"
        width="65%"
        @closed="resetForm"
      >
        <el-form
          ref="dataForm"
          :inline="true"
          :model="formData"
          label-position="right"
          label-width="100px"
          style="padding-left: 40px"
        >
          <el-form-item label="订单号" prop="order_no">
            <el-input v-model="formData.order_no" style="width: 300px" />
          </el-form-item>
          <el-form-item label="商品名称" prop="goods_name">
            <el-input v-model="formData.goods_name" style="width: 300px" />
          </el-form-item>
          <el-form-item label="商品价格" prop="amount">
            <el-input v-model="formData.amount" style="width: 300px" />
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="formData.phone" style="width: 300px" />
          </el-form-item>
          <el-form-item label="订单状态" prop="order_status">
            <el-select
              v-model="formData.order_status"
              class="filter-item"
              placeholder="是否显示"
              style="width: 300px"
            >
              <el-option :key="1" label="已支付" :value="1" />
              <el-option :key="0" label="未支付" :value="0" />
            </el-select>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="resetForm">取 消</el-button>
          <el-button v-if="this.dialog.title == '编辑'" @click="edit"
            >确定</el-button
          >
          <el-button v-if="this.dialog.title == '新增'" @click="add"
            >添加</el-button
          >
        </span>
      </el-dialog>
      <!--    表单提交-->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      order_status: null,
      order_no: null,
      tables: {
        tableKey: 0,
        listLoading: false,
        currentPage: 1,
        pageSize: 10,
        total: 1,
        tableData: [],
      },
      dialog: {
        centerDialogVisible: false,
      },
      formData: {
        id: "",
        name: "",
        img: "",
        brief: "",
        original_price: "",
        current_price: "",
        desc: "",
        status: 1,
      },
    };
  },
  
  created() {
    this.getList();
  },
  
  methods: {
    rowClass() {
      return "background:#f2f2f2;color:#4d4d4d";
    },
    columnStyle({ columnIndex }) {
      if (columnIndex == 5) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color:red";
      }
    },
    getList() {
      this.tables.listLoading = true;
      const data = {
        order_status: this.order_status,
        order_no: this.order_no,
        pageSize: this.tables.pageSize,
        pageNum: this.tables.currentPage,
      };
      this.$fecth.post("order/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.tables.listLoading = false;
          this.tables.tableData = data.lists;
          this.tables.pageSize = data.pageSize;
          this.tables.total = data.total;
          this.tables.currentPage = data.pageNum;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleEdit(id) {
      this.dialog.title = "编辑";
      this.formData.id = id;
      const data = {
        id: id,
      };

      this.$fecth.post("order/getDataById", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.formData = data;
          this.dialog.centerDialogVisible = true;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    edit() {
      const data = {
        id: this.formData.id,
        order_status: this.formData.order_status,
        goods_name: this.formData.goods_name,
        amount: this.formData.amount,
        phone: this.formData.phone,
      };
      this.$fecth.post("order/edit", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.resetForm();
          this.getList();
           this.showTips(1, msg);
        } else {
          this.showTips(0, msg);
        }
      });
    },

    handleDel(id) {
      const data = {
        id: id,
      };
      this.$fecth.post("order/delete", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, '删除成功');
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    resetForm() {
      this.formData = {
        id: "",
        name: "",
        img: "",
        brief: "",
        original_price: "",
        current_price: "",
        desc: "",
        status: 1,
      };
      this.dialog.centerDialogVisible = false;
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    handleCurrentChange(val) {
      this.tables.currentPage = val;
      this.getList();
    },
    upload_img(file) {
      if (file.code == 200) {
        this.formData.img = file.data;
      } else {
        this.showTips(0, file.data);
      }
    },
  },
};
</script>